import React, { useEffect, useState } from 'react';
import Gallery from '../views/gallery';

const GcsFileList = () => {
  const [fileUrls, setFileUrls] = useState([]);

  useEffect(() => {
    const bucketName = 'thomaslenard-gallery';
    const apiUrl = `https://storage.googleapis.com/storage/v1/b/${bucketName}/o`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (data.items) {
          // Sort items by file name
          const sortedItems = data.items.sort((a, b) => a.name.localeCompare(b.name));

          // Map sorted items to URLs
          const urls = sortedItems.map(
            (item) => `https://storage.googleapis.com/${bucketName}/${item.name}`
          );
          setFileUrls(urls);
        } else {
          console.error('No items found in the bucket.');
        }
      })
      .catch(error => console.error('Error fetching files:', error));
  }, []);

  return <Gallery fileUrls={fileUrls} />;
};

export default GcsFileList;
