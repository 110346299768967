import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className="AppBar"
        elevation={0}  // Remove shadow
        sx={{
          color: 'black',
          backgroundColor: 'white',
          padding: '0 16px 20px',
        }}
      >
        <Toolbar>
          <Typography
            className="AppBarTitle"
            sx={{
              flexGrow: 1,
              fontSize: { xs: '20px', md: '30px' }, // 20px on small devices, 30px on medium and larger
            }}
          >
            Thomas Lenard
          </Typography>
                {/* Email on the left */}
      <Typography variant="body2" color="textSecondary">
        Get in touch: <span/>
        <Link href="mailto:tslenard@gmail.com" color="inherit">
          tslenard@gmail.com
        </Link>
      </Typography>
          <IconButton
            color="inherit"
            aria-label="Instagram"
            href="https://www.instagram.com/thomaslenard.art/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
