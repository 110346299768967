import './App.css';
import LayoutContainer from './components/containers/layout-container'

function App() {
  return (
    <div className="App">
      <LayoutContainer key='layout'/>
    </div>
  );
}

export default App;
