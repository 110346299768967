import React, { Component } from 'react'
import GalleryContainer from './gallery-container'
import AppBar from '../views/appbar';
import Footer from '../views/footer';

class LayoutContainer extends Component {
    render () {
        return <div>
            <AppBar />
            <GalleryContainer />
            <Footer />
        </div>
    }
}


export default LayoutContainer