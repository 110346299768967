import * as React from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function ImageMasonry({ fileUrls }) {
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(null);
  };

  const handleNext = () => {
    if (selectedIndex < fileUrls.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrevious();
    }
  };

  React.useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, selectedIndex, fileUrls.length]);

  return (
    <Box sx={{ width: '100%', minHeight: '100%' }}>
      <Masonry columns={{ xs: 2, sm: 2, md: 3 }} spacing={2}>
        {fileUrls.map((url, index) => (
          <div key={index} onClick={() => handleOpen(index)}>
            <img
              srcSet={`${url}?w=162&auto=format&dpr=2 2x`}
              src={`${url}?w=162&auto=format`}
              alt={`Image ${index + 1}`}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
                display: 'block',
                width: '100%',
                cursor: 'pointer',
              }}
            />
          </div>
        ))}
      </Masonry>

      {/* Full-screen dialog for selected image */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            overflow: 'hidden',
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        <IconButton
          onClick={handlePrevious}
          disabled={selectedIndex === 0}
          sx={{
            position: 'absolute',
            left: 16,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
            '&.Mui-disabled': {
              display: 'none',
            },
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>

        <IconButton
          onClick={handleNext}
          disabled={selectedIndex === fileUrls.length - 1}
          sx={{
            position: 'absolute',
            right: 16,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
            '&.Mui-disabled': {
              display: 'none',
            },
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            height: '100vh',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
        >
          {selectedIndex !== null && (
            <img
              src={fileUrls[selectedIndex]}
              alt="Full-size"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
