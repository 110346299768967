import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        px: 2,
        mt: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >

      {/* Copyright on the right */}
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} Thomas Lenard
      </Typography>
    </Box>
  );
}
